/* Code information
   ch stands for safety tag
END */

* {
	font-family: 'Montserrat', sans-serif;
}

.st-header * a {
	text-decoration: none;
	color: inherit;
}
.st-header {
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	height: 3.75rem;
	justify-content: space-between;
	width: 100%;
	max-width: 100%;
	background-color: white;
	z-index: 1000;
	box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.6);
	padding: 0 2rem;
}

.st-header-nav {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 3.5rem;
	margin: auto;
	width: 100%;
	max-width: 75rem;
}

.st-header-nav-links {
	display: flex;
	width: 70%;
	justify-content: flex-end;
	align-items: center;
}

.st-header-nav-links > .st-header-link {
	margin-left: 1.875rem;
}
.st-grey-text {
	font-weight: 600;
	font-size: 0.75rem;
	text-transform: uppercase;
	text-decoration: none;
	color: var(--trip-text-gray);
}

.st-header-nav-links > .st-header-link.st-selected,
.st-header-nav-links > .st-header-link:hover {
	border-bottom: var(--pi-labs-blue) solid 1.5px;
}

.st-content-filler {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	margin: auto;
	padding-top: 3.75rem;
	max-width: 75rem;
	height: fit-content;
}

.st-header-logo {
	margin-top: 5%;
}

button.MuiButton-textPrimary:hover {
	background-color: rgba(0, 186, 255, 0.2);
}
button.MuiButton-textSecondary:hover {
	background-color: rgba(245, 0, 87, 0.2);
}

div > button.st-create-btn > span > span {
	margin-left: 3px !important;
}
.st-hide-content-beneath {
	position: sticky;
	top: 56px;
	left: 0;
	width: calc(100% - 20px);
	height: 66px;
	background-color: white;
	z-index: 1;
}

@media screen and (max-width: 75rem) {
	.st-content-filler {
		padding: 3.5rem 2rem 2rem 2rem;
	}
}
